html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  color-scheme: light dark;
  -webkit-user-select: none;
  user-select: none;
  font-family: sans-serif;
  font-size: 14px;
}
/*# sourceMappingURL=index.1982815b.css.map */
