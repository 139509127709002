html,
body {
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0;
	margin: 0;
}

body {
	font-family: sans-serif;
	font-size: 14px;
	color-scheme: light dark;
	user-select: none;
}
